/* eslint-disable react/no-multi-comp */
import { resendConfirmationMail } from "app/pages/Auth/AuthActions";
import { Route, Routes, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import Separator from "app/pages/.shared/Separator";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { useCallback } from "react";
import PropTypes from "prop-types";
import "./RegisterMessage.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const RegisterMessage = ({ email, shop, partner = {}, helpContactUrl, resendConfirmationMail }) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const today = new Date();
	const teasingEndDate = new Date(partner?.teasingEndDate);
	const isTeasing = today < new Date(teasingEndDate);

	const handleResendConfirmationMail = useCallback(() => {
		resendConfirmationMail({ email, shop })
			.then(() => navigate({ pathname: "./resend-confirmed" }))
			.catch(err => {
				if (err.response.status === 400) {
					navigate({ pathname: "/technical-error/confirm-registration-failed" });
				} else if (err.response.status === 404 || err.response.status === 500) {
					navigate({ pathname: "./user-not-found" });
				}
				throw err;
			});
	}, [email, shop]);

	return (
		<Routes>
			<Route
				path="/registration-succeeded"
				element={
					<div className="register-message">
						<div className="register-message__title">
							<FormattedMessage id="auth.register.success.title" />
						</div>
						<div className="register-message__body">
							{isTeasing ? (
								<>
									<FormattedMessage
										id="auth.register.success.teasing.message.1"
										values={{
											email: <b>{email}</b>,
										}}
									/>
									<div>
										<FormattedMessage
											id="auth.register.success.teasing.message.2"
											values={{
												b: chunks => <b>{chunks}</b>,
											}}
										/>
									</div>
								</>
							) : (
								<>
									<FormattedMessage
										id="auth.register.success.message.1"
										values={{
											email: <b>{email}</b>,
										}}
									/>
									<div>
										<FormattedMessage
											id="auth.register.success.message.2"
											values={{
												b: chunks => <b>{chunks}</b>,
											}}
										/>
									</div>
								</>
							)}
						</div>
						<Separator design="middle" />

						<div className="register-message__title">
							<FormattedMessage id="auth.register.success.resend.title" />
						</div>
						<div className="register-message__body register-message__body--left">
							<div>
								<FormattedMessage id="auth.register.success.resend.list.item.1" />
							</div>
							<div>
								<FormattedMessage
									id="auth.register.success.resend.list.item.2"
									values={{
										url: (
											<RelativeLink
												to={{
													pathname: isTeasing
														? "/teasing"
														: "/home/signup",
												}}
												className="register-message__link"
											>
												<FormattedMessage id="auth.register.change.here" />
											</RelativeLink>
										),
									}}
								/>
							</div>
							<div>
								<FormattedMessage
									id="auth.register.success.resend.list.item.3"
									values={{
										callCenter: (
											<a
												className="register-message__link"
												href={helpContactUrl}
												target="_blank"
												rel="noopener noreferrer"
											>
												<FormattedMessage id="auth.register.contact.callcenter" />
											</a>
										),
									}}
								/>
							</div>
						</div>
					</div>
				}
			/>

			<Route
				path="/registration-confirmed"
				element={
					<div className="register-message">
						<div className="register-message__title">
							<FormattedMessage id="auth.register.confirmed.success.title" />
						</div>
						<div className="register-message__body">
							<FormattedMessage id="auth.register.confirmed.success.message.1" />
							<div>
								<FormattedMessage
									id="auth.register.confirmed.success.message.2"
									values={{
										date: (
											<span className="register-message__highlight">
												{intl.formatDate(teasingEndDate, {
													year: "numeric",
													month: "long",
													day: "numeric",
												})}
											</span>
										),
									}}
								/>
							</div>
						</div>
					</div>
				}
			/>
			<Route
				path="/registration-not-confirmed"
				element={
					<div className="register-message">
						<div className="register-message__body">
							<FormattedMessage
								id="auth.register.not.confirmed.body"
								values={{
									email: <b>{email}</b>,
								}}
							/>
						</div>
						<Button variant="primary" onClick={handleResendConfirmationMail}>
							<FormattedMessage id="auth.register.not.confirmed.cta.label" />
						</Button>
					</div>
				}
			/>

			<Route
				path="/resend-confirmed"
				element={
					<div className="register-message">
						<div className="register-message__body">
							<FormattedMessage id="auth.register.confirmation.mail.resend.message" />
						</div>

						<Button
							variant="inverted"
							onClick={() => {
								navigate(isTeasing ? "/teasing" : "../signup");
							}}
						>
							<FormattedMessage id="general.back" />
						</Button>
					</div>
				}
			/>
			<Route
				path="/user-not-found"
				element={
					<div className="register-message">
						<div className="register-message__body">
							<FormattedMessage id="error.user.not.identified" />
						</div>
						<Button
							variant="inverted"
							onClick={() => {
								navigate(isTeasing ? "/teasing" : "../signup");
							}}
						>
							<FormattedMessage id="general.back" />
						</Button>
					</div>
				}
			/>
		</Routes>
	);
};

RegisterMessage.propTypes = {
	email: PropTypes.string,
	partner: PropTypes.object,
	helpContactUrl: PropTypes.string,
	shop: PropTypes.string,
	resendConfirmationMail: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ resendConfirmationMail }, dispatch);
};

export default connect(
	null,
	mapDispatchToProps
)(RegisterMessage);
