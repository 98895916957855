import { memo } from "react";
import classNames from "classnames";
import "./SimpleHeader.scss";
import { sendTagOnContactCall } from "app/utils/analytics";
import PropTypes from "prop-types";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { brandPropTypes } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import { useLocation } from "react-router";
import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";
import { FS_QUOTE_EVENT_NAME } from "app/constants";
import useOrangeHeader from "app/utils/hooks/useOrangeHeader";

const SimpleHeader = ({
	previousUrl,
	dialPhone,
	hideBackButton = false,
	brand,
	operatorEmail,
	isInApp,
	forceBrowserBack,
}) => {
	const { hit: fsHit } = useFlagship();
	const location = useLocation();
	const isBookingQuotePage = location?.pathname === "/booking/quote";
	const headerClass = classNames("simple-header", {
		"simple-header--eh": brand === BRANDS.EH || brand === BRANDS.EK,
	});

	const handleFlagShipEvent = () => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FS_QUOTE_EVENT_NAME.BACK,
		});
	};

	const handleClick = () => {
		if (isBookingQuotePage) {
			handleFlagShipEvent();
		}
	};

	if (brand === BRANDS.OV) {
		useOrangeHeader({ display: false });
	}

	return isInApp ? (
		false
	) : (
		<div className={headerClass}>
			<div className="simple-header__back">
				{!hideBackButton && (
					<BackButton
						to={{
							pathname: previousUrl,
						}}
						onClick={handleClick}
						forceBrowserBack={forceBrowserBack}
						label="general.back"
					/>
				)}
			</div>
			<div className="simple-header__title">
				<LogoBrandContainer
					isWhite={
						brand === BRANDS.CD ||
						brand === BRANDS.EK ||
						brand === BRANDS.HP ||
						brand === BRANDS.GP ||
						brand === BRANDS.SE ||
						brand === BRANDS.TZ
					}
				/>
			</div>
			{operatorEmail && (
				<div className="simple-header__operator">
					<strong>Agent :</strong> {operatorEmail}
				</div>
			)}
			<div className="simple-header__phone">
				{dialPhone && (
					<a
						className="simple-header__phone-button"
						onClick={() => {
							sendTagOnContactCall();
						}}
						href={`tel:${dialPhone}`}
					>
						<IconPhone height={30} width={30} />
					</a>
				)}
			</div>
		</div>
	);
};

SimpleHeader.propTypes = {
	brand: brandPropTypes,
	dialPhone: PropTypes.string,
	previousUrl: PropTypes.string,
	hideBackButton: PropTypes.bool,
	operatorEmail: PropTypes.string,
	isInApp: PropTypes.bool,
	forceBrowserBack: PropTypes.bool,
};

export default memo(SimpleHeader);
