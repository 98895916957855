import { createSelector } from "reselect";
import {
	BRANDS,
	OFFER_PRICE_TYPES,
	ONE_MERCH_BANNER_CATEGORY,
	PRODUCTS_FILTERS_KEY,
	RESOLUTION,
} from "app/constants";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import get from "lodash/get";
import includes from "lodash/includes";
import { FIRST_MERCHANDISING_POSITION } from "src/@brand/appConstants";
import { zonedTimeToUtc } from "date-fns-tz";
import uniq from "lodash/uniq";
import flatten from "lodash/flatten";
import pick from "lodash/pick";
import compact from "lodash/compact";
import { FacetedClassification, FacetedQuery } from "immfacet";
import { fromJS } from "immutable";
import { getDayCount } from "app/utils/utils";
import forOwn from "lodash/forOwn";
import { isBetweenDates } from "app/utils/utils";

import { sortProducts } from "app/utils/utils.js";

const getMerchandising = state => state.merchandising;
const merchandisings = state => state.merchandising.list;
const getActiveFilter = state => state.merchandising.activeFilter;
const getCurrentMerchandising = state => state.merchandising.current;
const getProducts = state => state.products;
const getProductsFilters = state => state.productsFilters;
const getProductsOfMerchandising = state => state.merchandising.products;
const getResolution = state => state.resolution;
const getPartner = state => state.partner;
const getBrand = state => state.brand.code;
const getSpotlightUri = (state, spotlightUri = null) => spotlightUri;

export const getMerchPreviewStartDate = createSelector(
	[getMerchandising],
	(merchandising = {}) => {
		return merchandising.previewStartDate;
	}
);

/**
 * On ne veut pas les merch PARRAINAGE si le système de parrainage n'est pas actif
 */
export const getVisibleMerchandisings = createSelector(
	[merchandisings, getPartner, getMerchPreviewStartDate],
	(merchandisings = [], partner = {}, previewStartDate) => {
		// previewStartDate est valorisé par la query param ?preview=YYYYMMDD
		const previewDateTimestamp = zonedTimeToUtc(previewStartDate, "YYYYMMDD");

		if (previewStartDate) {
			return merchandisings.filter(merchandising => {
				const merchEndAtTimestamp = new Date(merchandising.endAt).getTime();
				const merchStartAtTimestamp = new Date(merchandising.startAt).getTime();

				if (
					previewDateTimestamp &&
					(previewDateTimestamp >= merchEndAtTimestamp ||
						previewDateTimestamp < merchStartAtTimestamp)
				) {
					return false;
				}

				if (!merchandising.partnerCode || partner.code === merchandising.partnerCode) {
					if (merchandising.code === "PARRAINAGE") {
						return partner.enableSponsorship;
					}

					if (merchandising.code === "PAIEMENT4X") {
						return partner.code === "AFFR" || partner.code === "EKFR";
					}

					return true;
				}

				return false;
			});
		}

		return merchandisings.filter(merch => isBetweenDates(merch.startAt, merch.endAt));
	}
);

/**
 * La listing PP n'affiche uniquement que les merchs non catégorisés
 */
export const getUncategorizedVisibleMerchandisings = createSelector(
	[getVisibleMerchandisings],
	(merchandisings = []) => {
		return merchandisings.filter(merchandising => {
			return !merchandising.displayOptions?.category;
		});
	}
);

/**
 * La listing PP n'affiche uniquement que les merchs non catégorisés
 */
export const getCategorizedVisibleMerchandisings = createSelector(
	[getVisibleMerchandisings],
	(merchandisings = []) => {
		const filteredMerchandisings = merchandisings.filter(merchandising => {
			return merchandising.displayOptions?.category?.name;
		});
		return sortBy(filteredMerchandisings, "startAt");
	}
);

/**
 * La listing PP n'affiche uniquement que les merchs non catégorisés
 */
export const getOneMerchBannerMerchandising = createSelector(
	[getCategorizedVisibleMerchandisings],
	(merchandisings = []) => {
		return merchandisings.find(merchandising => {
			return merchandising.displayOptions?.category?.name === ONE_MERCH_BANNER_CATEGORY;
		});
	}
);

export const getMerchandisingByPosition = (position, idSpotlight) => {
	return createSelector(
		[getUncategorizedVisibleMerchandisings, getProducts, getResolution],
		(merchandisings = [], products, resolution) => {
			return {
				position,
				merchList: sortBy(
					merchandisings.filter(merch => {
						if (
							(resolution === RESOLUTION.LARGE ||
								resolution === RESOLUTION.XLARGE ||
								resolution === RESOLUTION.MEDIUM) &&
							(!merch.photoLarge ||
								!merch.photoLarge.url ||
								merch.photoLarge.url === "")
						) {
							return false;
						} else if (
							resolution === RESOLUTION.SMALL &&
							(!merch.photoSmall ||
								(!merch.photoSmall.url && merch.photoSmall.url === ""))
						) {
							return false;
						}

						const spotlightProduct = find(products, product => {
							return product.uri === idSpotlight;
						});

						if (spotlightProduct) {
							return merch.fallbackPosition === position;
						}
						return merch.position === position;
					}),
					"headerIndex"
				),
			};
		}
	);
};

export const getMerchandisingTopicsFilterValuesFromMerchCode = createSelector(
	[getCurrentMerchandising],
	(merchandising = {}) => {
		let merchandisingTopics = [];

		if (merchandising.merchandisingTopics) {
			merchandisingTopics = merchandising.merchandisingTopics.reduce(
				(filterValues, { filterValue: currentFilterValue }) => {
					return currentFilterValue
						? [...filterValues, currentFilterValue]
						: filterValues;
				},
				[]
			);
		}

		return merchandisingTopics;
	}
);

export const getMerchandisingRange0 = createSelector(
	[getUncategorizedVisibleMerchandisings, getProducts, getResolution, getSpotlightUri],
	(merchandisings = [], products = [], resolution, idSpotlight) => {
		return {
			position: FIRST_MERCHANDISING_POSITION,
			merchList: sortBy(
				merchandisings.filter(merch => {
					if (
						(resolution === RESOLUTION.LARGE ||
							resolution === RESOLUTION.XLARGE ||
							resolution === RESOLUTION.MEDIUM) &&
						(!merch.photoLarge || !merch.photoLarge.url || merch.photoLarge.url === "")
					) {
						return false;
					} else if (
						resolution === RESOLUTION.SMALL &&
						(!merch.photoSmall ||
							(!merch.photoSmall.url && merch.photoSmall.url === ""))
					) {
						return false;
					}

					const hasSpotlightProduct = products.some(product => {
						return product.uri === idSpotlight;
					});

					if (hasSpotlightProduct) {
						return merch.fallbackPosition === FIRST_MERCHANDISING_POSITION;
					}

					return merch.position === FIRST_MERCHANDISING_POSITION;
				}),
				"headerIndex"
			),
		};
	}
);

export const getListingHeaderMerchandisings = createSelector(
	[getMerchandisingRange0, getBrand],
	(merchandisings, brand) => {
		if (brand === BRANDS.EK && get(merchandisings, "merchList.length") >= 3) {
			const minimumSlidesListLength = 6;
			let doubleMerchandisingList = merchandisings.merchList;
			if (merchandisings.merchList.length) {
				const maxIteration = minimumSlidesListLength / merchandisings.merchList.length || 1;
				for (let factor = 1; factor < maxIteration; factor++) {
					doubleMerchandisingList = [
						...doubleMerchandisingList,
						...merchandisings.merchList,
					];
				}
				return { ...merchandisings, merchList: doubleMerchandisingList };
			}
		}
		return merchandisings;
	}
);

const getMerchandisingProductsFacetQuery = createSelector(
	[getProductsOfMerchandising, getActiveFilter, getProductsFilters],
	(products = [], activeFilter, productsFilters = {}) => {
		let facetCollection = new FacetedClassification(fromJS(products));

		facetCollection = facetCollection
			.addFacet(
				PRODUCTS_FILTERS_KEY.TOPIC,
				product => {
					if (!product.get("topics")) {
						return undefined;
					}
					if (
						activeFilter &&
						(!product.get("merchandisingTopics") ||
							!includes(product.get("merchandisingTopics").toJS(), activeFilter))
					) {
						return undefined;
					}
					return product.get("topics").toJS();
				},
				{
					multiValue: true,
				}
			)
			.addFieldFacet(PRODUCTS_FILTERS_KEY.MERCHANDISINGS, {
				multiValue: true,
			})
			.addFacet(PRODUCTS_FILTERS_KEY.DESTINATION, product => {
				if (!product.get("country")) {
					return undefined;
				}

				if (
					activeFilter &&
					(!product.get("merchandisingTopics") ||
						!includes(product.get("merchandisingTopics").toJS(), activeFilter))
				) {
					return undefined;
				}

				return product.get("country");
			})
			.addFacet(
				PRODUCTS_FILTERS_KEY.ZONE,
				product => {
					if (!product.get("zones")) {
						return undefined;
					}
					if (
						activeFilter &&
						(!product.get("merchandisingTopics") ||
							!includes(product.get("merchandisingTopics").toJS(), activeFilter))
					) {
						return undefined;
					}
					return product
						.get("zones")
						.toJS()
						.map(zone => {
							return zone;
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.CATEGORY,
				product => {
					if (!product.get("merchandisingTopics")) {
						return undefined;
					}
					if (
						activeFilter &&
						(!product.get("merchandisingTopics") ||
							!includes(product.get("merchandisingTopics").toJS(), activeFilter))
					) {
						return undefined;
					}
					return product.get("merchandisingTopics").toJS();
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.BADGE,
				product => {
					if (!product.get("badges")) {
						return undefined;
					}
					if (
						activeFilter &&
						(!product.get("merchandisingTopics") ||
							!includes(product.get("merchandisingTopics").toJS(), activeFilter))
					) {
						return undefined;
					}
					return product
						.get("badges")
						.toJS()
						.map(badge => {
							return badge.label;
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.PERIOD,
				product => {
					if (!product.get("periods")) {
						return undefined;
					}
					if (
						activeFilter &&
						(!product.get("merchandisingTopics") ||
							!includes(product.get("merchandisingTopics").toJS(), activeFilter))
					) {
						return undefined;
					}
					return product
						.get("periods")
						.toJS()
						.map(period => {
							return period.label;
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				PRODUCTS_FILTERS_KEY.MONTH,
				product => {
					if (!product.get("months")) {
						return undefined;
					}
					if (
						activeFilter &&
						(!product.get("merchandisingTopics") ||
							!includes(product.get("merchandisingTopics").toJS(), activeFilter))
					) {
						return undefined;
					}
					return product
						.get("months")
						.toJS()
						.map(month => {
							return String(month.startAt);
						});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(PRODUCTS_FILTERS_KEY.START_AT, product => {
				if (!product.get("startAt")) {
					return undefined;
				}
				if (
					activeFilter &&
					(!product.get("merchandisingTopics") ||
						!includes(product.get("merchandisingTopics").toJS(), activeFilter))
				) {
					return undefined;
				}
				return String(
					getDayCount(product.get("startAt"), Date.now()) <= 2 &&
						getDayCount(product.get("startAt"), Date.now()) >= 0
				);
			})
			.addFacet(PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE, product => {
				if (!product.get("fromPriceType")) {
					return undefined;
				}
				if (
					activeFilter &&
					(!product.get("merchandisingTopics") ||
						!includes(product.get("merchandisingTopics").toJS(), activeFilter))
				) {
					return undefined;
				}

				return String(
					product.get("fromPriceType").toJS().type ===
						OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO
						? Number(product.get("fromPriceType").toJS().value) >= 50
						: undefined
				);
			})
			.addFacet(PRODUCTS_FILTERS_KEY.END_AT, product => {
				if (!product.get("endAt")) {
					return undefined;
				}
				if (
					activeFilter &&
					(!product.get("merchandisingTopics") ||
						!includes(product.get("merchandisingTopics").toJS(), activeFilter))
				) {
					return undefined;
				}

				return String(
					getDayCount(Date.now(), product.get("endAt")) <= 2 &&
						getDayCount(Date.now(), product.get("endAt")) >= 0
				);
			});

		let facetQuery = new FacetedQuery(facetCollection);

		forOwn(productsFilters, (value, key) => {
			// on doit gérer les valeurs en string et boolean (pour startAt et endAt)
			if ((value && value.length > 0) || (value && typeof value === "boolean")) {
				facetQuery = facetQuery.select({
					name: key,
					values:
						typeof value === "string" || typeof value === "boolean" ? [value] : value,
				});
			}
		});

		return facetQuery;
	}
);

export const getFilteredProductsOfMerchandising = createSelector(
	[getActiveFilter, getMerchandisingProductsFacetQuery],
	(activeFilter, facetQuery) => {
		const productsFiltered = sortProducts({ products: facetQuery.selectedItems().toJS() });

		if (activeFilter) {
			return productsFiltered.filter(product => {
				return includes(product.merchandisingTopics, activeFilter);
			});
		}
		return productsFiltered;
	}
);

export const getVisibleProductsOfMerchandising = createSelector(
	[getFilteredProductsOfMerchandising],
	(products = []) => {
		return products;
	}
);

export const getMerchandisingMedias = createSelector(
	[getCurrentMerchandising],
	(merchandising = {}) => {
		const medias = get(merchandising, "media.articles");
		return medias;
	}
);

export const getMerchandisingVisibleFilters = createSelector(
	[getMerchandisingProductsFacetQuery],
	(facetQuery = {}) => {
		return facetQuery.selectedFacetValues().toJS() || [];
	}
);

export const getMerchandisingSelectedFacets = createSelector(
	[getMerchandisingProductsFacetQuery],
	(facetQuery = {}) => {
		return facetQuery.selectedFacets().toJS() || [];
	}
);

export const getMerchandisingDestinationFilterValues = createSelector(
	[getVisibleProductsOfMerchandising],
	(products = []) => {
		if (!products || products.length === 0) {
			return [];
		}

		const countries = products.map(product => {
			return product.country || [];
		});

		return sortBy(uniq(flatten(countries)));
	}
);

export const getMerchandisingTopicFilterValues = createSelector(
	[getVisibleProductsOfMerchandising],
	(products = []) => {
		if (!products || products.length === 0) {
			return [];
		}

		const topics = products.map(product => {
			return product.topics || [];
		});

		return sortBy(uniq(flatten(topics)));
	}
);

export const getMerchandisingPeriodFilterValues = createSelector(
	[getVisibleProductsOfMerchandising],
	(products = []) => {
		const periods = products.map(product => {
			if (!product.periods || product.periods.length === 0) {
				return [];
			}
			return product.periods || [];
		});

		const sortedPeriods = sortBy(flatten(periods), "startAt");

		const labels = sortedPeriods.map(period => {
			return pick(period, "label").label || [];
		});

		return uniq(labels);
	}
);

export const getMerchandisingMonthFilterValues = createSelector(
	[getVisibleProductsOfMerchandising],
	(products = []) => {
		const months = products.map(product => {
			if (!product.months || product.months.length === 0) {
				return [];
			}
			return product.months || [];
		});

		const sortedMonths = sortBy(flatten(months), "startAt");

		const labels = sortedMonths.map(month => {
			return String(pick(month, "startAt").startAt) || [];
		});

		return uniq(labels);
	}
);

export const getMerchandisingBadgeFilterValues = createSelector(
	[getVisibleProductsOfMerchandising],
	(products = []) => {
		const badges = products.map(product => {
			if (!product.badges || product.badges.length === 0) {
				return [];
			}
			return product.badges || [];
		});

		const firstBadges = badges.map(badgeList => {
			return badgeList[0];
		});

		const sortedBadges = sortBy(flatten(compact(firstBadges)), "label");

		const badgeLabels = sortedBadges.map(badge => {
			return pick(badge, "label").label || [];
		});

		return uniq(badgeLabels);
	}
);
