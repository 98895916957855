import {
	FETCH_MY_BOOKING,
	FETCH_MY_BOOKINGS,
	PAY_DUE_BOOKING,
	FETCH_MY_BOOKINGS_NOTIFICATION,
} from "app/actionTypes";
import { getCredentials } from "app/utils/auth";
import axios from "axios";
import env from "app/utils/env";
import { getStore } from "app/configureStore";

export const payDueBooking = paymentPayload => {
	const { token } = getCredentials();
	const url = `${env("ACCOUNT_API_URL")}/addPayment`;
	const headers = {
		"Content-type": "application/json",
	};

	// Si on a un token, cad paiement depuis mon compte, alors on n'envoie pas la brand ni le shop
	// Si on n'a pas de token, cas paiement depuis le site institutionnel, alors on doit fourni la brand et la shop
	if (token) {
		headers.Authorization = token;
		delete paymentPayload.brand;
		delete paymentPayload.shop;
	}

	return {
		type: PAY_DUE_BOOKING,
		promise: axios.post(url, paymentPayload, { headers }),
	};
};

export const fetchMyBookings = () => {
	const { token } = getStore().getState().auth;
	const url = `${env("ACCOUNT_API_URL")}/getBookings`;
	const headers = {
		"Content-type": "application/json",
		Authorization: token,
	};
	return {
		type: FETCH_MY_BOOKINGS,
		promise: axios.post(url, {}, { headers }),
	};
};

export const fetchMyBooking = bookingReference => {
	const { token } = getStore().getState().auth;
	const url = `${env("ACCOUNT_API_URL")}/getBookings`;
	const headers = {
		"Content-type": "application/json",
		Authorization: token,
	};
	return {
		type: FETCH_MY_BOOKING,
		promise: axios.post(url, { bookingReference }, { headers }),
	};
};

export const fetchMyBookingsNotification = ({ email, shop, brand }) => {
	const { token } = getCredentials();
	const url = `${env("ACCOUNT_API_URL")}/getCustomerRequests/count`;
	const headers = {
		"Content-type": "application/json",
		Authorization: token,
	};
	return {
		type: FETCH_MY_BOOKINGS_NOTIFICATION,
		promise: axios.get(url, {
			headers,
			params: { brandCode: brand, shopCode: shop, email },
		}),
	};
};
