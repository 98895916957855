import PerfectApp from "./PerfectApp.jsx";
import PropTypes from "prop-types";
import { getCredentialsWithTokenId, setSessionBiid, setUserBiid } from "app/pages/Auth/AuthActions";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import Cookies from "js-cookie";
import {
	BRANDS,
	COOKIES_AUTH_EXPIRES,
	FS_RESOLUTIONS,
	SESSION_BIID_EXPIRATION_MAX_AGE,
	STRATEGY,
} from "app/constants";
import { isWhiteLabel } from "app/reducers/brandSelector";
import { getBrandData, getPartnerData } from "app/reducers/partnerActionCreators";
import { getUUID, isAuthenticated } from "app/pages/Auth/authSelectors";
import { brandPropTypes, resolutionPropType, strategyShape } from "app/utils/propTypes";
import { fetchCoupons } from "app/pages/Account/MyCoupons/couponActionCreators";
import AppGlobalsContext from "app/AppGlobalsContext";
import { fetchPaymentMethods } from "app/pages/Booking/Payment/paymentActionCreators";
import { v4 as uuidv4 } from "uuid";
import { isExpiredAfter } from "app/utils/utils";
import { FlagshipProvider } from "@flagship.io/react-sdk";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import {
	finishLoadingBar,
	showLoadingBar,
} from "app/pages/.shared/LoadingBar/loadingBarActionCreators";
import { LoadingBar } from "app/pages/.shared/LoadingBar/LoadingBar";
import { useLocation } from "react-router-dom";
import useVirtualPageLoading from "app/utils/analytics/useVirtualPageLoading";
import { getDeltaBasePrice } from "app/pages/Booking/bookingSelectors";

const PerfectAppContainer = props => {
	const {
		enableCredits,
		enableCreditNotes,
		isAuthenticated,
		fetchCoupons,
		strategy,
		brand,
		getCredentialsWithTokenId,
		getPartnerData,
		shop,
		resolution,
		fetchPaymentMethods,
		partnerCode,
		setSessionBiid,
		setUserBiid,
		sessionBiid,
		userBiid,
		sessionBiidExpirationDate,
		productsVisibility,
		envVars = {},
		uuid,
		showLoadingBar,
		deltaBasePrice,
		finishLoadingBar,
	} = props;

	const { track } = useAnalytics();

	useVirtualPageLoading();

	const { pathname } = useLocation();
	const [prevLoc, setPrevLoc] = useState("");

	// https://dev.to/ataparvinghods/react-progress-bar-on-route-changing-both-next-cra-3d27
	useEffect(() => {
		setPrevLoc(pathname);
		showLoadingBar();
		if (pathname === prevLoc) {
			setPrevLoc("");
		}
	}, [pathname]);

	useEffect(() => {
		finishLoadingBar();
	}, [prevLoc]);

	useEffect(() => {
		const auth = Cookies.get("auth");

		// si un ancien cookie auth est présent ...
		if (auth) {
			// ... on le met dans le cookie fr-FR
			Cookies.remove("auth");
			Cookies.set("auth", auth, { expires: COOKIES_AUTH_EXPIRES, path: "/fr-FR" });
		}

		const partner = Cookies.get("partner");

		if (partner) {
			Cookies.remove("partner");
			Cookies.set("partner", partner, { path: "/fr-FR" });
		}

		getPartnerData();

		fetchPaymentMethods();

		if (
			!sessionBiid ||
			isExpiredAfter(sessionBiidExpirationDate, SESSION_BIID_EXPIRATION_MAX_AGE)
		) {
			setSessionBiid(`${partnerCode}-${uuidv4()}`, Date.now());
		}

		if (!userBiid) {
			setUserBiid(`${partnerCode}-${uuidv4()}`);
		}
	}, []);

	useEffect(() => {
		// Les nouveaux tokens disposent désormais d'un tokenId.
		// Au démarrage de l'appli, on verifie si le token dispose d'un tokenId
		// si ce n'est pas le cas, alors on en regénère un nouveau avec tokenId et on remplace l'ancien
		// Seulement ppour les brands avec api membre
		if (strategy === STRATEGY.SIGNUP_FIRST && brand !== BRANDS.XX) {
			const token = props.token;
			if (token) {
				getCredentialsWithTokenId(token);
			}
		}
	}, [strategy]);

	useEffect(() => {
		if ((enableCredits || enableCreditNotes) && isAuthenticated) {
			fetchCoupons();
		}
	}, [enableCredits, enableCreditNotes, isAuthenticated]);

	return (
		<AppGlobalsContext.Provider
			value={{
				brand: brand,
				shop,
				resolution,
				strategy,
				partnerCode,
				productsVisibility,
				envVars,
			}}
		>
			<FlagshipProvider
				envId={envVars.FLAGSHIP_ENV_ID}
				apiKey={envVars.FLAGSHIP_APP_KEY}
				onUserExposure={({ flagData = {} }) => {
					if (flagData.key) {
						track("user_exposed_to_abtest", {
							abtest: {
								abTestName: flagData.metadata?.slug,
								flagName: flagData.key,
								flagValue: flagData.value,
							},
						});
					}
				}}
				visitorData={{
					id: uuid || sessionBiid,
					context: {
						resolution: FS_RESOLUTIONS[resolution],
						brand,
						shop,
						strategy,
						deltaBasePrice,
					},
					isAuthenticated,
				}}
			>
				<LoadingBar />
				<PerfectApp {...props} />
			</FlagshipProvider>
		</AppGlobalsContext.Provider>
	);
};

PerfectAppContainer.propTypes = {
	envVars: PropTypes.object,
	productsVisibility: PropTypes.string,
	fetchCoupons: PropTypes.func,
	getPartnerData: PropTypes.func,
	getBrandData: PropTypes.func,
	getCredentialsWithTokenId: PropTypes.func,
	fetchPaymentMethods: PropTypes.func,
	token: PropTypes.string,
	shop: PropTypes.string,
	resolution: resolutionPropType,
	brand: brandPropTypes,
	strategy: strategyShape,
	isAuthenticated: PropTypes.bool,
	enableCredits: PropTypes.bool,
	enableCreditNotes: PropTypes.bool,
	partnerCode: PropTypes.string,
	setSessionBiid: PropTypes.func,
	registerExposedAbTest: PropTypes.func,
	sessionBiid: PropTypes.string,
	sessionBiidExpirationDate: PropTypes.number,
	setUserBiid: PropTypes.func,
	userBiid: PropTypes.string,
	showLoadingBar: PropTypes.func,
	finishLoadingBar: PropTypes.func,
	uuid: PropTypes.string,
	deltaBasePrice: PropTypes.number,
	email: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		token: state.auth.token,
		uuid: getUUID(state),
		deltaBasePrice: getDeltaBasePrice(state),
		sessionBiidExpirationDate: state.auth.sessionBiidExpirationDate,
		sessionBiid: state.auth.sessionBiid,
		userBiid: state.auth.userBiid,
		shop: state.shop,
		brand: state.brand.code,
		strategy: state.partner.strategy,
		enableCredits: state.partner.enableCredits,
		enableCreditNotes: state.partner.enableCreditNotes,
		isAuthenticated: isAuthenticated(state),
		isWhiteLabel: isWhiteLabel(state),
		productsVisibility: state.productsVisibility,
		partnerCode: state.partner.code,
		envVars: state.envVars,
		showSmartDPOnRootUrl: state.partner.showSmartDPOnRootUrl,
		resolution: state.resolution,
		email: state.email,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			getCredentialsWithTokenId,
			getPartnerData,
			getBrandData,
			fetchCoupons,
			fetchPaymentMethods,
			setSessionBiid,
			setUserBiid,
			showLoadingBar,
			finishLoadingBar,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PerfectAppContainer);
