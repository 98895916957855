import { createSelector } from "reselect";
import without from "lodash/without";
import flatten from "lodash/flatten";
import { BRANDS, SHOP_COUNTRY_LABELS } from "app/constants";
import { getPartnerMarketing } from "app/reducers/partnerSelector";

const getBrandCode = state => state.brand.code;
const getBrandShops = state => state.brand.shops;
const getMarketing = state => getPartnerMarketing(state);
const getDestinationsList = state => state.smartDP.destinations;

export const getCurrentShop = state => state.shop;

export const getTopDestinations = createSelector(
	[getMarketing, getDestinationsList],
	(marketing = {}, destinationsList = []) => {
		const topDestinationsListCode = marketing?.topDestinations?.map(destination => ({
			// eslint-disable-next-line radix
			code: parseInt(destination?._ref?.split(".")[1]),
		}));

		return destinationsList
			.filter(item => topDestinationsListCode?.some(x => x.code === item.code))
			.sort(
				(item1, item2) =>
					topDestinationsListCode.findIndex(x => x.code === item1.code) -
					topDestinationsListCode.findIndex(x => x.code === item2.code)
			);
	}
);

export const getSwitchableShops = createSelector(
	[getBrandCode, getBrandShops, getCurrentShop],
	(brandCode, shops = [], currentShop) => {
		// on veut masquer de-DE pour la collection air france. A supprimer quand AFIT et AFES seront disponibles
		let switchableShops = flatten(
			shops.map(shop => {
				const locales = shop.locales;

				return locales.map(locale => {
					return `${locale}-${shop.sellingCountry}`;
				});
			})
		);

		if (brandCode === BRANDS.AF && currentShop !== "es-ES" && currentShop !== "it-IT") {
			switchableShops = without(switchableShops, "it-IT", "es-ES");
		}

		return switchableShops.map(shopCode => {
			return {
				code: shopCode,
				label: SHOP_COUNTRY_LABELS[shopCode],
			};
		});
	}
);

export const getAllSwitchableShops = createSelector(
	[getBrandCode, getBrandShops],
	(brandCode, shops = []) => {
		let switchableShops = flatten(
			shops.map(shop => {
				const locales = shop.locales;

				return locales.map(locale => {
					return `${locale}-${shop.sellingCountry}`;
				});
			})
		);

		return switchableShops.map(shopCode => {
			return {
				code: shopCode,
				label: SHOP_COUNTRY_LABELS[shopCode],
			};
		});
	}
);
