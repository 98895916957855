import PropTypes from "prop-types";
import { Form, Formik, Field } from "formik";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import FacebookContainer from "app/pages/Auth/Facebook/FacebookContainer.jsx";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import PasswordPolicyValidation from "app/pages/Auth/Signup/PasswordPolicyValidation";
import { ALERT_TYPE } from "app/constants";
import AlertMessage from "app/pages/.shared/AlertMessage";
import CheckboxFormik from "app/pages/.shared/form/CheckboxFormik";
import InputFormik from "app/pages/.shared/form/InputFormik";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import { object, string, boolean } from "yup";
import { PASSWORD_POLICY_REGEX, PASSWORD_LIMIT_CHARS } from "app/constants";
import { sendTagOnEmailSignupError } from "app/utils/analytics";
import useShowPassword from "app/utils/hooks/useShowPassword";

import "../auth.scss";

const validationSchema = object().shape({
	email: string()
		.required({ id: "error.email.required" })
		.email({ id: "error.email.format" }),
	password: string()
		.min(PASSWORD_LIMIT_CHARS, { id: "auth.password.policy.not.valid" })
		.required({ id: "error.password.required" })
		.test(
			"atLeastOneUppercase",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.UPPERCASE.test(value)
		)
		.test(
			"atLeastOneDigit",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.DIGIT.test(value)
		)
		.test(
			"atLeastOneSpecialChar",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.SPECIAL_CHAR.test(value)
		),
	terms: boolean()
		.required({ id: "error.must.accept.terms" })
		.oneOf([true], { id: "error.must.accept.terms" }),
});

const Signup = ({
	handleSubmit,
	showTopFacebook = true,
	facebookOnSuccess,
	onToogleSigninForm,
	cnilDocumentName,
	confidentialiteDocumentName,
	signupButtonLabel = <FormattedMessage id="auth.sign.up.button.label" />,
	headline = <FormattedMessage id="auth.signup.headline" />,
	footer = null,
}) => {
	const [icon, showPassword] = useShowPassword();
	return (
		<div className="auth">
			<div className="auth__main">
				<div className="auth__switch">
					<div
						className="auth__switch__item auth__switch__item--active"
						data-testid="switch-to-signUp"
					>
						<FormattedMessage id="home.signup.button.label" />
					</div>
					<div
						className="auth__switch__item"
						onClick={onToogleSigninForm}
						data-testid="switch-to-login"
					>
						<FormattedMessage id="home.signin.button.label" />
					</div>
				</div>

				{headline && (
					<header className="auth__headline" data-testid="auth-headline">
						{headline}
					</header>
				)}

				<Formik
					initialValues={{
						email: "",
						password: "",
						terms: false,
					}}
					onSubmit={handleSubmit}
					validateOnChange={false}
					validateOnBlur={false}
					validationSchema={validationSchema}
				>
					{({
						errors = {},
						values = {},
						isSubmitting,
						setSubmitting,
						setStatus,
						submitCount,
					}) => {
						return (
							<Form
								noValidate
								className="auth__form"
								onChange={() => {
									setStatus({});
								}}
							>
								{showTopFacebook && (
									<>
										<div
											className="auth__facebook"
											data-testid="auth-facebook-top"
										>
											<FacebookContainer
												signup
												onSuccess={facebookOnSuccess}
												onError={() => setSubmitting(false)}
											/>
										</div>
										<div className="auth__separator">
											<FormattedMessage id="general.ou" />
										</div>
									</>
								)}
								<FormErrorMessages
									errors={errors}
									isSubmitting={isSubmitting}
									submitCount={submitCount}
									onError={() =>
										sendTagOnEmailSignupError({
											status: "",
											error: errors[Object.keys(errors)[0]]?.id,
										})
									}
								/>
								<InputFormik
									type="email"
									name="email"
									id="auth-email"
									data-testid="email-input"
									label={<FormattedMessage id="auth.email.label" />}
								/>

								<InputFormik
									type={showPassword ? "text" : "password"}
									name="password"
									id="auth-password"
									data-testid="password-input"
									label={<FormattedMessage id="auth.password.label" />}
									className="auth__password"
									note={icon}
								/>
								<PasswordPolicyValidation value={values.password} />
								<div className="auth__accept-cgv">
									<Field
										component={CheckboxFormik}
										name="terms"
										id="accept-terms"
										grouped={false}
									>
										<div className="auth__terms" data-testid="auth-terms">
											<FormattedMessage
												id="auth.terms.label"
												values={{
													cguLink: (
														<a
															className="relative-link"
															rel="noopener noreferrer"
															target="_blank"
															href={confidentialiteDocumentName}
														>
															<FormattedMessage id="auth.terms.label.cgu.link.label" />
														</a>
													),
													confidentialiteLink: (
														<a
															className="relative-link"
															rel="noopener noreferrer"
															target="_blank"
															href={confidentialiteDocumentName}
														>
															<FormattedMessage id="auth.terms.label.confidentialite.link.label" />
														</a>
													),
												}}
											/>
										</div>
									</Field>
									{cnilDocumentName && (
										<a
											rel="noopener noreferrer"
											target="_blank"
											href={cnilDocumentName}
											data-testid="cnil-document"
										>
											<IconInfo width={15} height={15} />
										</a>
									)}
								</div>
								<div className="auth__action">
									{errors._error && (
										<AlertMessage
											alertType={ALERT_TYPE.ERROR}
											message={<FormattedMessage id={errors._error.id} />}
										/>
									)}
									<Button
										className="auth__button"
										submit
										variant="primary"
										loading={isSubmitting}
										data-testid="signup-button"
									>
										{signupButtonLabel}
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
				{footer && footer.props?.children && <div className="auth__footer">{footer}</div>}
			</div>
		</div>
	);
};

Signup.propTypes = {
	signupButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	showTopFacebook: PropTypes.bool,
	cnilDocumentName: PropTypes.string,
	confidentialiteDocumentName: PropTypes.string,
	facebookOnSuccess: PropTypes.func,
	onToogleSigninForm: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default Signup;
