export default {
	home: {
		id: "page.title.home",
	},
	listing: {
		id: "page.title.listing",
	},
	landing: {
		id: "page.title.landing",
	},
	teasing: {
		id: "page.title.teasing",
	},
	merch: {
		id: "page.title.merch",
		description: "Merchandising page title",
		defaultMessage: "{label} - {siteTitle}",
	},
	profile: {
		id: "page.title.profile",
	},
	experts: {
		id: "page.title.experts",
	},
	help: {
		id: "page.title.help",
	},
	product: {
		id: "page.title.product",
	},
	"landing-product": {
		id: "page.title.product",
	},
	subscription: {
		id: "page.title.subscription",
	},
	wishlist: {
		id: "page.title.wishlist",
	},
	mybookings: {
		id: "page.title.mybookings",
	},
	"404": {
		id: "page.title.404",
	},
	"search-booking": {
		id: "page.title.search.booking",
	},
	"recover-booking-data": {
		id: "page.title.recover.booking.data",
	},
};
