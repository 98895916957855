import { connect } from "react-redux";
import SimpleHeader from "app/pages/Header/SimpleHeader/SimpleHeader";
import withRouter from "app/utils/hocs/withRouter";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { hasCustomDynamicHeader } from "app/reducers/brandSelector";
import { parseQueries } from "app/utils/routerUtils";
import { checkIfComingFromRemarketingEmail } from "app/utils/urlDataExtractor";

const mapStateToProps = (state, props) => {
	const currentPathname = props.location.pathname;

	const {
		utm_source,
		utm_medium,
		productUri,
		redirectToProductPage: isComingFromNavlogs,
	} = parseQueries(props.location.search);

	const isComingFromRemarketingEmail = checkIfComingFromRemarketingEmail(utm_source, utm_medium);

	const isQuotationPage = currentPathname.includes("/booking/quote");
	const isPaiementPage = currentPathname.includes("/booking/payment");
	const isConfirmationPage = currentPathname.includes("/booking/confirmation");
	const isAuthPage = currentPathname.includes("/auth");
	const isLandingProductPage = currentPathname.includes("/product/");

	const contextCallCenter = getContextCallCenter({
		isPaymentPage: isPaiementPage,
		isQuotationPage,
		isConfirmationPage,
	})(state);

	const brand = state.brand.code;

	return {
		previousUrl: `/${productUri}`,
		brand,
		operatorEmail: state.auth.operatorEmail,
		hideBackButton:
			isAuthPage ||
			isLandingProductPage ||
			isConfirmationPage ||
			hasCustomDynamicHeader(state),
		...contextCallCenter,
		isInApp: state.navigation.isInApp,
		// we want to go back to product page when coming from marketing email and navlogs
		forceBrowserBack: !isComingFromRemarketingEmail && !isComingFromNavlogs,
	};
};

export default withRouter(connect(mapStateToProps)(SimpleHeader));
