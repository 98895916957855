import { createSelector } from "reselect";
import Cookies from "js-cookie";

const getAuth = state => state.auth;
const getShop = state => state.shop;

export const isOperatorMode = createSelector(
	[getAuth],
	(auth = {}) => {
		return Boolean(auth.operatorEmail);
	}
);

export const getUUID = createSelector(
	[getAuth],
	(auth = {}) => {
		return auth.uuid;
	}
);

export const getSessionBiid = createSelector(
	[getAuth],
	(auth = {}) => {
		return auth.sessionBiid;
	}
);

/**
 * Vérifie si l'utilisateur est authentifié ou pas
 * Préférer cette méthode plutot que getCredentials si on a besoin de savoir cote serveur si le user est authentifié ou non
 * @returns {boolean} true si authentifié
 */
export const isAuthenticated = createSelector(
	[getAuth, getShop],
	(auth = {}, shop = "") => {
		return Cookies.get("auth", { path: `/${shop}` }) === "auth" && Boolean(auth.token);
	}
);

export const getAuthToken = createSelector(
	[getAuth],
	(auth = {}) => {
		return auth.token;
	}
);
export const getAuthTokenFromPartner = createSelector(
	[getAuth],
	(auth = {}) => {
		return auth.tokenFromPartner;
	}
);
