import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Helmet from "react-helmet";
import descriptionMessages from "./description";
import titleMessages from "./title";
import { getShop } from "app/utils/shop";
import { OFFER_PRICE_TYPES } from "app/constants";
import env from "app/utils/env";
import {
	getBrandDisplayName,
	getMetaTags,
	getRobotsMetaTag,
	getTheme,
} from "app/reducers/partnerSelector";
import uniqBy from "lodash/uniqBy";
import flattenDeep from "lodash/flattenDeep";

const titleByName = name => (formatMessage, data) => {
	const siteTitle = data.brandDisplayName;
	return formatMessage(titleMessages[name], Object.assign({}, { siteTitle }, data));
};

const _title = {
	default: titleByName("home"),
	"search-booking": titleByName("search-booking"),
	"recover-booking-data": titleByName("recover-booking-data"),
	landing: titleByName("landing"),
	teasing: titleByName("teasing"),
	listing: titleByName("listing"),
	merch: titleByName("merch"),
	signup: titleByName("home"),
	login: titleByName("home"),
	resetpassword: titleByName("home"),
	changepassword: titleByName("home"),
	subscription: titleByName("subscription"),
	profile: titleByName("profile"),
	auth: titleByName("home"),
	product: titleByName("product"),
	"404": titleByName("404"),
};

const metaByName = name => (formatMessage, data) => {
	let descriptionName = name;

	// Dans le cas de la page produit, la description change en fonction du type de prix (A partir de X€, jusqu'à X% ou exclusif)
	if (name === "product" || name === "landing-product") {
		switch (data.pricingType) {
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO:
				descriptionName = "productSaveUpTo";
				break;
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM:
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT:
				descriptionName = "productFrom";
				break;
			case OFFER_PRICE_TYPES.FROM_PRICE_TYPE_EXCLUSIVE:
				descriptionName = "productExclusive";
				break;
			default:
				descriptionName = "productExclusive";
				break;
		}
	}

	const siteTitle = data ? data.brandDisplayName : "";

	return [
		{
			property: "og:title",
			content: formatMessage(titleMessages[name], Object.assign({}, { siteTitle }, data)),
		},
		{
			name: "description",
			content: formatMessage(
				descriptionMessages[descriptionName],
				Object.assign({}, { siteTitle }, data)
			),
		},
		{
			property: "og:description",
			content: formatMessage(
				descriptionMessages[descriptionName],
				Object.assign({}, { siteTitle }, data)
			),
		},
	];
};

const _meta = {
	default: (formatMessage, data) => {
		const brand = data.brand;
		const robotsMetaTag = data.robotsMetaTag;
		const metaTags = data.metaTags;
		const siteTitle = data.brandDisplayName;

		const ogImage = `https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/brands/${brand}/og-${brand.toLowerCase()}.jpg`;
		return [
			{
				name: "apple-mobile-web-app-title",
				content: siteTitle,
			},
			{
				name: "language",
				content: getShop(),
			},
			{
				name: "robots",
				content: robotsMetaTag?.default,
			},
			{
				name: "googlebot",
				content: robotsMetaTag?.default,
			},
			{
				name: "google-site-verification",
				content: data.googleSiteVerificationId,
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:image",
				content: ogImage,
			},
			{
				name: "thumbnail",
				content: ogImage,
			},
			{
				property: "fb:app_id",
				content: env("FACEBOOK_APP_ID"),
			},
			...metaTags,
		].concat(metaByName("home")(formatMessage, data));
	},
	merch: (formatMessage, data) => {
		const robotsMetaTag = data.robotsMetaTag;
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: robotsMetaTag?.merch,
			},
			{
				name: "googlebot",
				content: robotsMetaTag?.merch,
			},
			...metaTags,
		].concat(metaByName("merch")(formatMessage, data));
	},
	listing: (formatMessage, data) => {
		const robotsMetaTag = data.robotsMetaTag;
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: robotsMetaTag?.listing,
			},
			{
				name: "googlebot",
				content: robotsMetaTag?.listing,
			},
			...metaTags,
		].concat(metaByName("listing")(formatMessage, data));
	},
	landing: (formatMessage, data) => {
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
			...metaTags,
		];
	},
	teasing: (formatMessage, data) => {
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
			...metaTags,
		];
	},
	subscription: metaByName("subscription"),
	profile: metaByName("profile"),
	login: (formatMessage, data) => {
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: "index, follow",
			},
			{
				name: "googlebot",
				content: "index, follow",
			},
			...metaTags,
		];
	},
	"search-booking": (formatMessage, data) => {
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: "index, follow",
			},
			{
				name: "googlebot",
				content: "index, follow",
			},
			...metaTags,
		];
	},
	"recover-booking-data": (formatMessage, data) => {
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
			...metaTags,
		];
	},
	signup: (formatMessage, data) => {
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: "index, follow",
			},
			{
				name: "googlebot",
				content: "index, follow",
			},
			...metaTags,
		];
	},
	product: (formatMessage, data) => {
		const metaTags = data.metaTags;
		const robotsMetaTag = data.robotsMetaTag;
		return [
			{
				name: "robots",
				content: robotsMetaTag?.product,
			},
			{
				name: "googlebot",
				content: robotsMetaTag?.product,
			},
			{
				property: "og:image",
				content: data.photo,
			},
			{
				name: "thumbnail",
				content: data.photo,
			},
			...metaTags,
		].concat(metaByName("product")(formatMessage, data));
	},
	"landing-product": (formatMessage, data) => {
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: "noindex, nofollow",
			},
			{
				name: "googlebot",
				content: "noindex, nofollow",
			},
			{
				property: "og:image",
				content: data.photo,
			},
			{
				name: "thumbnail",
				content: data.photo,
			},
			...metaTags,
		].concat(metaByName("landing-product")(formatMessage, data));
	},
	"404": metaByName("404"),
	"sdp-search": (formatMessage, data) => {
		const robotsMetaTag = data.robotsMetaTag;
		const metaTags = data.metaTags;
		return [
			{
				name: "robots",
				content: robotsMetaTag?.sdpSearch,
			},
			{
				name: "googlebot",
				content: robotsMetaTag?.sdpSearch,
			},
			...metaTags,
		];
	},
};

const canonical = path => ({
	rel: "canonical",
	href: `${env("BASE_URL")}/${getShop()}${path}`,
});

const alternate = (path, shops = []) => {
	const domainsByShop = shops.map((shopData = {}) => {
		const marketingCodes = shopData.marketingCodes || [];
		const domainsByMarketingCodes = marketingCodes.map(marketingCode => {
			const shop = `${marketingCode.locale}-${shopData.sellingCountry}`;
			return {
				rel: "alternate",
				hreflang: shop === "fr-FR" ? shop.slice(0, 2) : shop,
				href: `${env("BASE_URL")}/${shop}${path}`,
			};
		});

		return domainsByMarketingCodes;
	});

	return uniqBy(flattenDeep(domainsByShop), domain => domain.hreflang + domain.href);
};

const _link = {
	default: (formatMessage, { brand = "" }, theme) => [
		{
			rel: "icon",
			href: theme?.favicon
				? theme.favicon
				: `https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto/brands/${brand}/favicon-${brand.toLowerCase()}.png`,
		},
	],
	listing: (formatMessage, { shops = [] }) => [
		canonical("/listing"),
		...alternate("/listing", shops),
	],
	teasing: (formatMessage, { shops = [] }) => [
		canonical("/teasing"),
		...alternate("/teasing", shops),
	],
	landing: (formatMessage, { shops = [] }) => [canonical("/lp"), ...alternate("/lp", shops)],
	signup: (formatMessage, { shops = [] }) => [
		canonical("/home/login"),
		...alternate("/home/signup", shops),
	],
	login: (formatMessage, { shops = [] }) => [
		canonical("/home/login"),
		...alternate("/home/login", shops),
	],
	resetpassword: (formatMessage, { shops = [] }) => [
		canonical("/home/login"),
		...alternate("/home/resetpassword", shops),
	],
	changepassword: (formatMessage, { shops = [] }) => [
		canonical("/home/login"),
		...alternate("/home/changepassword", shops),
	],
	subscription: (formatMessage, { shops = [] }) => [
		canonical("/subscriptions"),
		...alternate("/subscriptions", shops),
	],
	profile: (formatMessage, { shops = [] }) => [
		canonical("/account/profile"),
		...alternate("/account/profile", shops),
	],
	auth: (formatMessage, { shops = [] }) => [
		canonical("/home/login"),
		...alternate("/auth", shops),
	],
	product: (formatMessage, { id, shops = [] }) => [
		canonical(`/${id}`),
		...alternate(`/${id}`, shops),
	],
	"landing-product": (formatMessage, { id, shops = [] }) => [
		canonical(`/product/${id}`),
		...alternate(`/product/${id}`, shops),
	],
	"search-booking": (formatMessage, { shops = [] }) => [canonical("/"), ...alternate("/", shops)],
	"sdp-search": (formatMessage, { shops = [] }) => [canonical(""), ...alternate("", shops)],
	"recover-booking-data": (formatMessage, { shops = [] }) => [
		canonical("/"),
		...alternate("/", shops),
	],
};

const pageGetter = (page, formatMessage, data, theme) => {
	const _ret = {};
	if (typeof _title[page] === "function") {
		_ret.title = _title[page](formatMessage, data);
	}
	if (typeof _meta[page] === "function") {
		_ret.meta = _meta[page](formatMessage, data);
	}
	if (typeof _link[page] === "function") {
		_ret.link = _link[page](formatMessage, data, theme);
	}
	return _ret;
};

const getDynamicStyles = theme => {
	return [
		{
			cssText: `
            body {
                --color-primary: ${theme?.colors?.colorPrimary?.hex};
								--color-primary-dark: ${theme?.colors?.colorPrimaryDark?.hex};
								--color-primary-light: ${theme?.colors?.colorPrimaryLight?.hex};

								--color-secondary: ${theme?.colors?.colorSecondary?.hex};
								--color-secondary-dark: ${theme?.colors?.colorSecondaryDark?.hex};
								--color-secondary-light: ${theme?.colors?.colorSecondaryLight?.hex};

								--black: ${theme?.colors?.black?.hex};
								--grey-dark: ${theme?.colors?.greyDark?.hex};
								--grey-light: ${theme?.colors?.greyLight?.hex};
								--grey-medium: ${theme?.colors?.greyMedium?.hex};

								--error: ${theme?.colors?.error?.hex};
								--valid: ${theme?.colors?.valid?.hex};
								--font-family: ${theme?.font?.enableCustomFont ? theme?.font?.fontFamily : "opensans"};
            }
        `,
		},
	];
};

const getFontFromTheme = theme => theme?.font?.fontFamily?.replace(" ", "+");

const HeadMetadata = ({
	intl: { formatMessage },
	data,
	page,
	robotsMetaTag,
	metaTags = [],
	googleSiteVerificationId,
	brandDisplayName,
	brand,
	shop,
	shops = [],
	prefetchRessources = [],
	theme = {},
	...props
}) => {
	const completeData = Object.assign({}, data, {
		robotsMetaTag,
		metaTags,
		brandDisplayName,
		googleSiteVerificationId,
		brand,
		shops,
	});

	const head = pageGetter(page, formatMessage, completeData, theme);
	const styles = getDynamicStyles(theme);
	const fontFamily = getFontFromTheme(theme);

	const shouldCustomizeFont = theme?.font?.enableCustomFont;

	head.link = head.link
		? head.link.concat(
				prefetchRessources.map(ressource => {
					return { rel: "preload", href: ressource, as: "image" };
				})
		  )
		: [];

	// preload logo svg so the logo is displayed in the print version of the product page
	// of brand where there is a dynamic header
	head.link = head.link
		? head.link.concat(
				[
					{
						rel: "preload",
						href: `https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-${brand}-${shop}-white.svg`,
						as: "image",
						type: "image/svg+xml",
					},
				],
				[
					{
						rel: "preload",
						href: `https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-${brand}-${shop}.svg`,
						as: "image",
						type: "image/svg+xml",
					},
				]
		  )
		: [];

	head.link = head.link.concat([
		{ rel: "preconnect", href: "https://res.cloudinary.com" },
		{ rel: "preconnect", href: "https://connect.facebook.net" },
		{ rel: "preconnect", href: "https://www.tripadvisor.com" },
		{ rel: "preconnect", href: "https://www.googletagmanager.com" },
		{ rel: "preconnect", href: "https://www.google-analytics.com" },
		{ rel: "preconnect", href: "https://x6pc9ierpi.execute-api.eu-west-1.amazonaws.com" },
	]);

	if (shouldCustomizeFont && fontFamily) {
		head.link = head.link.concat([
			{ rel: "preconnect", href: "https://fonts.googleapis.com" },
			{ rel: "preconnect", href: "https://fonts.gstatic.com", crossorigin: true },
			{
				rel: "stylesheet",
				href: `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400;600&display=swap`,
			},
		]);
	}

	return <Helmet style={styles} {...head} {...props} />;
};

HeadMetadata.propTypes = {
	intl: PropTypes.object,
	data: PropTypes.object,
	googleSiteVerificationId: PropTypes.string,
	brandDisplayName: PropTypes.string,
	robotsMetaTag: PropTypes.object,
	metaTags: PropTypes.array,
	brand: PropTypes.string,
	shop: PropTypes.string,
	shops: PropTypes.array,
	page: PropTypes.string.isRequired,
	prefetchRessources: PropTypes.arrayOf(PropTypes.string),
	theme: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		brandDisplayName: getBrandDisplayName(state),
		googleSiteVerificationId: state.partner.googleSiteVerificationId,
		robotsMetaTag: getRobotsMetaTag(state),
		metaTags: getMetaTags(state),
		brand: state.brand.code,
		shop: state.shop,
		shops: state.brand.shops,
		theme: getTheme(state),
	};
};

export default injectIntl(connect(mapStateToProps)(HeadMetadata));
