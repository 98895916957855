export const fetchMessages = (apiURL, brand, shop) => {
	const lang = shop.slice(0, 2);

	const getMainMessages = import(/* webpackChunkName: "messages-main-i18n" */ `./messages/${lang}.json`);
	const getBrandMessages = import(/* webpackChunkName: "messages-brand-i18n" */ `./messages/brands/${brand}/${lang}.json`);

	return Promise.all([getMainMessages, getBrandMessages])
		.then(([messages, brandsMessages]) => {
			return {
				...messages,
				...brandsMessages,
			};
		})
		.catch(error => {
			// TODO connecter Sentry
			// eslint-disable-next-line no-console
			console.error("fetchMessages error", error);
		});
};
