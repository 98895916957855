import { createSelector } from "reselect";
import { BRANDS } from "app/constants";
import get from "lodash/get";
import { getAuthToken, getAuthTokenFromPartner } from "app/pages/Auth/authSelectors";

const getBrand = state => state.brand;
const getProfile = state => state.profile;

export const shouldShowFrequencyModal = createSelector(
	[getBrand, getProfile],
	(brand = {}, profile = {}) => {
		return (
			brand.code === BRANDS.AX &&
			get(profile, "newsletterPreference.shouldDisplayFrequencyModal") === true
		);
	}
);

/*
 *	le but est de savoir quand on peut appeler /me quand pour preremplir le profile
 *	la condition est d'avoir un token d'authentification (AF, TO EK etc ...) ou un tokenFromPartner (VP, etc ...)
 */
export const shouldGetProfileAtPayment = createSelector(
	[getAuthToken, getAuthTokenFromPartner],
	(authToken, authTokenFromPartner) => {
		return Boolean(authToken || authTokenFromPartner);
	}
);
