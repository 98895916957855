import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import RelativeLink from "app/pages/.shared/RelativeLink";
import FacebookContainer from "app/pages/Auth/Facebook/FacebookContainer.jsx";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";
import { getParentPath } from "app/utils/routerUtils";
import InputFormik from "app/pages/.shared/form/InputFormik";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import { object, string } from "yup";
import LoginCountdown from "app/pages/Auth/Signin/LoginCountdown";
import useShowPassword from "app/utils/hooks/useShowPassword";

import "../auth.scss";

const validationSchema = object().shape({
	email: string()
		.required({ id: "error.email.required" })
		.email({ id: "error.email.format" }),
	password: string().required({ id: "error.password.required" }),
});

const Login = ({
	email,
	handleSubmit = () => {},
	facebookOnSuccess = () => {},
	onToogleSignupForm = () => {},
	showTopFacebook = true,
	homeRedirection = true,
	headline = <FormattedMessage id="auth.signin.headline" />,
	footer = null,
	canLoginAt,
	setCanLoginAt = () => {},
}) => {
	const location = useLocation();
	const parentPath = getParentPath(location.pathname) || "/home";
	const now = Date.now();
	const [icon, showPassword] = useShowPassword();

	return (
		<div className="auth">
			<div className="auth__main">
				<div className="auth__switch">
					<div
						className="auth__switch__item"
						onClick={onToogleSignupForm}
						data-testid="switch-to-signUp"
					>
						<FormattedMessage id="home.signup.button.label" />
					</div>
					<div
						className="auth__switch__item auth__switch__item--active"
						data-testid="switch-to-login"
					>
						<FormattedMessage id="home.signin.button.label" />
					</div>
				</div>

				{headline && (
					<header className="auth__headline" data-testid="auth-headline">
						{headline}
					</header>
				)}

				<Formik
					initialValues={{
						email: email,
						password: "",
					}}
					onSubmit={handleSubmit}
					validateOnChange={false}
					validateOnBlur={false}
					validationSchema={validationSchema}
				>
					{({
						errors = {},
						isSubmitting,
						setSubmitting,
						setStatus,
						setErrors,
						submitCount,
					}) => {
						return (
							<Form
								noValidate
								className="auth__form"
								onChange={() => {
									setStatus({});
								}}
							>
								{showTopFacebook && (
									<div>
										<div
											className="auth__facebook"
											data-testid="auth-facebook-top"
										>
											<FacebookContainer
												onSuccess={facebookOnSuccess}
												onError={() => setSubmitting(false)}
											/>
										</div>

										<div className="auth__separator">
											<FormattedMessage id="general.ou" />
										</div>
									</div>
								)}

								<FormErrorMessages
									errors={errors}
									isSubmitting={isSubmitting}
									submitCount={submitCount}
								/>
								<InputFormik
									type="email"
									name="email"
									id="auth-email"
									data-testid="email-input"
									label={<FormattedMessage id="auth.email.label" />}
								/>
								<InputFormik
									type={showPassword ? "text" : "password"}
									name="password"
									id="auth-password"
									data-testid="password-input"
									label={<FormattedMessage id="auth.password.label" />}
									className="auth__password"
									note={icon}
								/>

								<div className="auth__forgotten-password">
									<RelativeLink
										to={{
											pathname: `${
												homeRedirection ? "/home" : parentPath
											}/resetpassword`,
										}}
										data-testid="forgot-password-button"
									>
										<FormattedMessage id="auth.password.forgotten.label" />
									</RelativeLink>
								</div>

								<div className="auth__action">
									{!canLoginAt && errors._error && (
										<AlertMessage
											alertType={ALERT_TYPE.ERROR}
											message={
												<div className="auth__messages-error">
													<FormattedMessage id={errors._error.id} />
												</div>
											}
										/>
									)}
									{canLoginAt && canLoginAt > now && (
										<AlertMessage
											alertType={ALERT_TYPE.ERROR}
											message={
												<div className="auth__messages-error">
													<FormattedMessage
														id="error.block.login"
														values={{
															timer: (
																<LoginCountdown
																	date={canLoginAt}
																	onComplete={() => {
																		setCanLoginAt(undefined);
																		setStatus({});
																		setErrors({});
																	}}
																/>
															),
														}}
													/>
												</div>
											}
										/>
									)}
									<Button
										className="auth__button"
										submit
										variant="primary"
										loading={isSubmitting}
										data-testid="login-button"
										disabled={Boolean(canLoginAt) && canLoginAt > now}
									>
										<FormattedMessage id="auth.signin.button.label" />
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
				{footer && footer.props?.children && <div className="auth__footer">{footer}</div>}
			</div>
		</div>
	);
};

Login.propTypes = {
	email: PropTypes.string,
	handleSubmit: PropTypes.func,
	facebookOnSuccess: PropTypes.func,
	onToogleSignupForm: PropTypes.func,
	showTopFacebook: PropTypes.bool,
	homeRedirection: PropTypes.bool,
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	canLoginAt: PropTypes.number,
	setCanLoginAt: PropTypes.func,
};

export default Login;
