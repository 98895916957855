import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AsyncHomeContainer, AsyncSmartDPContainer } from "app/routes";
import { Navigate } from "react-router-dom";
import { BRANDS_WITH_LISTING, STRATEGY } from "app/constants";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import PropTypes from "prop-types";
import { brandPropTypes, strategyShape } from "app/utils/propTypes";

const RootContainer = ({ showSmartDPOnRootUrl, strategy, isAuthenticated, brand }) => {
	let redirectUrl;
	if (showSmartDPOnRootUrl) {
		return <AsyncSmartDPContainer />;
	}

	if (strategy === STRATEGY.TRANSACTION_FIRST) {
		redirectUrl = "/not-found";
		if (BRANDS_WITH_LISTING.includes(brand)) {
			redirectUrl = "/listing";
		}
	} else if (strategy === STRATEGY.OAUTH) {
		redirectUrl = "/listing";
	} else if (strategy === STRATEGY.SIGNUP_FIRST) {
		if (isAuthenticated) {
			redirectUrl = "/listing";
		} else {
			redirectUrl = "/home/login";
		}
	}

	if (redirectUrl) {
		return (
			<Navigate
				to={{
					pathname: redirectUrl,
				}}
			/>
		);
	}
	return <AsyncHomeContainer />;
};

RootContainer.propTypes = {
	showSmartDPOnRootUrl: PropTypes.bool,
	strategy: strategyShape,
	isAuthenticated: PropTypes.bool,
	brand: brandPropTypes,
};

const mapStateToProps = state => {
	return {
		brand: state.brand.code,
		showSmartDPOnRootUrl: state.partner.showSmartDPOnRootUrl,
		strategy: state.partner.strategy,
		isAuthenticated: isAuthenticated(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({}, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RootContainer);
