import PropTypes from "prop-types";
import { Fragment, memo, useContext } from "react";
import "./pages/.styles/index.scss";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import { brandPropTypes, strategyShape } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import { StickyContainer } from "react-sticky";
import { LoadingBar } from "app/pages/.shared/LoadingBar/LoadingBar";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import FooterContainer from "app/pages/Footer/FooterContainer";
import {
	AsyncAccountContainer,
	AsyncAuthBookingLayerContainer,
	AsyncAuthContainer,
	AsyncBooking,
	AsyncBookingHeaderContainer,
	AsyncFaqContainer,
	AsyncFicheProduitContainer,
	AsyncHeaderContainer,
	AsyncHomeContainer,
	AsyncHomeHeaderContainer,
	AsyncLandingAuthContainer,
	AsyncLandingPageContainer,
	AsyncLandingProductContainer,
	AsyncListingContainer,
	AsyncMerchandisingContainer,
	AsyncNotFound,
	AsyncPaymentExternalRedirect,
	AsyncPriceMatchGuarantee,
	AsyncSearchBookingPage,
	AsyncSearchMyBookingContainer,
	AsyncSimpleHeaderContainer,
	AsyncSmartDPContainer,
	AsyncStaticPageContainer,
	AsyncTechnicalErrorContainer,
	AsyncUnsubscribeContainer,
} from "app/routes";
import BookWithConfidence from "app/pages/.shared/BookWithConfidence/BookWithConfidence";
import AsidePanelContainer from "app/pages/.shared/AsidePanel/AsidePanelContainer";
import { AsideMenuAccountContainer } from "app/pages/.shared/AsideMenu/AsideMenuAccountContainer";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import AppGlobalsContext from "app/AppGlobalsContext";
import RequireSignupFirst from "app/pages/Auth/RequireSignupFirst";
import QueriesParser from "app/pages/.shared/QueriesParser/QueriesParser";
import RootContainer from "app/pages/Root/RootContainer";
import OperatorRedirect from "app/pages/.shared/OperatorRedirect/OperatorRedirect";
import DevInfos from "app/pages/.shared/DevInfos/DevInfos";

const PerfectApp = ({ authLayer, brand, isWhiteLabel, shop, isAuthenticated }) => {
	let routes;

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const { pathname } = useLocation();

	if (brand === BRANDS.XX) {
		routes = (
			<StickyContainer>
				<Routes>
					<Route path="/home/*" element={<></>} />
					<Route path="/faq" element={<></>} />
					<Route path="/account/*" element={<AsyncHeaderContainer />} />
					<Route
						path="/listing"
						element={!isAuthenticated ? <></> : <AsyncHeaderContainer />}
					/>
					<Route path="/not-found" element={<AsyncHeaderContainer />} />
					<Route path="/merch" element={<AsyncHeaderContainer />} />
					<Route path="/technical-error/*" element={<AsyncHeaderContainer />} />
					<Route path="/booking/*" element={<AsyncBookingHeaderContainer />} />
					<Route path="/priceMatchGuarantee" element={<AsyncHeaderContainer />} />
					<Route
						path="/:productUri"
						element={!isAuthenticated ? <></> : <AsyncHeaderContainer />}
					/>
				</Routes>

				<Routes>
					<Route
						path="/home/*"
						element={
							<Navigate
								to={{
									pathname: `/`,
								}}
							/>
						}
					/>
					<Route
						path="/account/*"
						element={
							!isAuthenticated ? (
								<Navigate
									to={{
										pathname: `/`,
									}}
								/>
							) : (
								<AsyncAccountContainer />
							)
						}
					/>
					<Route
						path="/auth"
						element={
							<Navigate
								to={{
									pathname: `/`,
								}}
							/>
						}
					/>
					<Route path="/booking/*" element={<AsyncBooking />} />
					<Route path="/external-redirect" element={<AsyncPaymentExternalRedirect />} />
					<Route path="/not-found" element={<AsyncNotFound />} />
					<Route path="/technical-error/*" element={<AsyncTechnicalErrorContainer />} />
					<Route path="/schizophrene" element={<OperatorRedirect />} />
					<Route
						path="/listing"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncListingContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>
					<Route
						path="/merch"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncMerchandisingContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>
					<Route path="/priceMatchGuarantee" element={<AsyncPriceMatchGuarantee />} />
					<Route path="/faq/*" element={<AsyncFaqContainer />} />
					<Route path="/page" element={<AsyncStaticPageContainer />} />
					<Route
						path="/sdp/*"
						element={
							<QueriesParser>
								<AsyncSmartDPContainer />
							</QueriesParser>
						}
					/>
					<Route
						path="/:productUri"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncFicheProduitContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>
					<Route
						path="/*"
						element={
							<QueriesParser>
								<AsyncSearchBookingPage />
							</QueriesParser>
						}
					/>
				</Routes>
			</StickyContainer>
		);
	} else {
		routes = (
			<StickyContainer>
				<Routes>
					<Route
						path="/home/*"
						element={
							brand !== BRANDS.AF && brand !== BRANDS.AX ? (
								<AsyncHomeHeaderContainer />
							) : (
								<></>
							)
						}
					/>
					<Route path="/auth" element={<></>} />
					<Route path="/unsubscribe" element={<AsyncHeaderContainer />} />
					<Route path="/account/*" element={<AsyncHeaderContainer />} />
					<Route path="/landing-page/*" element={<></>} />
					<Route path="/schizophrene" element={<></>} />
					<Route path="/priceMatchGuarantee" element={<AsyncHeaderContainer />} />
					<Route path="/booking/*" element={<AsyncBookingHeaderContainer />} />
					<Route path="/external-redirect" element={<></>} />
					<Route path="/not-found" element={<AsyncHeaderContainer />} />
					<Route path="/technical-error/*" element={<AsyncHeaderContainer />} />
					<Route path="/magazine" element={<></>} />
					<Route path="/lp" element={<></>} />
					<Route path="/faq" element={<></>} />
					<Route path="/page/:slug" element={<AsyncHeaderContainer />} />
					<Route path="/sdp/*" element={<></>} />
					<Route
						path="/listing"
						element={brand !== BRANDS.TZ ? <AsyncHeaderContainer /> : <></>}
					/>
					<Route path="/merch" element={<AsyncHeaderContainer />} />
					<Route path="/product/id/:productId" element={<AsyncHeaderContainer />} />
					<Route path="/product/:productUri" element={<AsyncSimpleHeaderContainer />} />
					<Route
						path="/booking-auth"
						element={isMobile ? <></> : <AsyncHeaderContainer />}
					/>
					<Route
						path="/booking-search"
						element={isMobile ? <></> : <AsyncHeaderContainer />}
					/>
					<Route path="/:productUri" element={<AsyncHeaderContainer />} />
					<Route path="/*" element={<></>} />
				</Routes>

				<Routes>
					<Route
						path="" // obliger de spécifier "" et "/" pour que les meta tags robots google bots soit correct et ne prenne pas les valeurs par défaut lors du server side rendering
						element={
							<QueriesParser>
								<RootContainer />
							</QueriesParser>
						}
					/>
					<Route
						path="/" // obliger de spécifier "" et "/" pour que les meta tags robots google bots soit correct et ne prenne pas les valeurs par défaut lors du server side rendering
						element={
							<QueriesParser>
								<RootContainer />
							</QueriesParser>
						}
					/>
					{isWhiteLabel && (
						<Route
							path="/home/*"
							element={
								<QueriesParser>
									<AsyncHomeContainer />
								</QueriesParser>
							}
						/>
					)}
					{isWhiteLabel && <Route path="/auth" element={<AsyncAuthContainer />} />}
					{isWhiteLabel && (
						<Route
							path="/unsubscribe"
							element={
								<QueriesParser>
									<AsyncUnsubscribeContainer />
								</QueriesParser>
							}
						/>
					)}
					{(isWhiteLabel || isAuthenticated) && (
						<Route path="/account/*" element={<AsyncAccountContainer />} />
					)}

					{isWhiteLabel && (
						<Route
							path="/landing-page/:operationCode/*"
							element={
								<QueriesParser>
									<AsyncLandingAuthContainer />
								</QueriesParser>
							}
						/>
					)}

					{brand === BRANDS.AF && (
						<Route
							path="/ourcommitments"
							element={<Navigate to={{ pathname: "/page/ourcommitments" }} />}
						/>
					)}

					<Route
						path="/product/id/:productId"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncFicheProduitContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>

					{isWhiteLabel && (
						<Route
							path="/product/:productUri"
							element={
								<QueriesParser>
									<AsyncLandingProductContainer />
								</QueriesParser>
							}
						/>
					)}
					<Route path="/schizophrene" element={<OperatorRedirect />} />
					<Route path="/priceMatchGuarantee" element={<AsyncPriceMatchGuarantee />} />
					<Route path="/booking/*" element={<AsyncBooking />} />
					<Route path="/external-redirect" element={<AsyncPaymentExternalRedirect />} />
					<Route path="/not-found" element={<AsyncNotFound />} />
					<Route path="/technical-error/*" element={<AsyncTechnicalErrorContainer />} />

					<Route path="/lp" element={<AsyncLandingPageContainer />} />
					<Route
						path="/listing"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncListingContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>
					<Route
						path="/merch"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncMerchandisingContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>

					<Route path="/faq/*" element={<AsyncFaqContainer />} />
					<Route path="/page/:slug" element={<AsyncStaticPageContainer />} />
					<Route
						path="/sdp/*"
						element={
							<QueriesParser>
								<AsyncSmartDPContainer />
							</QueriesParser>
						}
					/>

					<Route path="/booking-auth" element={<AsyncAuthBookingLayerContainer />} />

					<Route path="/booking-search" element={<AsyncSearchMyBookingContainer />} />

					<Route
						path="/:productUri"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncFicheProduitContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>

					<Route path="*" element={<AsyncNotFound />} />
				</Routes>
			</StickyContainer>
		);
	}

	return (
		<Fragment>
			<LoadingBar />

			<HeadMetadata page="default" />
			<AsidePanelContainer>
				<AsideMenuAccountContainer />
			</AsidePanelContainer>
			{authLayer}

			{routes}

			<Routes>
				{pathname !== "/faq" && pathname !== "/external-redirect" ? (
					<Route
						path="*"
						element={
							<>
								<BookWithConfidence shop={shop} />
								<FooterContainer />
							</>
						}
					/>
				) : (
					<></>
				)}
			</Routes>

			<DevInfos />
		</Fragment>
	);
};

PerfectApp.propTypes = {
	authLayer: PropTypes.element,
	brand: brandPropTypes,
	shop: PropTypes.string,
	strategy: strategyShape,
	isWhiteLabel: PropTypes.bool,
	isAuthenticated: PropTypes.bool,
	showSmartDPOnRootUrl: PropTypes.bool,
};

export default memo(PerfectApp);
